import $ from 'jquery';

export default function buttons() {
    $('.js-categories-next').click(function(e) {
        e.preventDefault();
        $(this).addClass('hidden');
        $('.js-categories-group').addClass('hidden');
        $('.js-additional-categories-group').css("display", "flex").hide().fadeIn(200);
        $('.js-categories-back').removeClass('hidden');
    })
    $('.js-categories-back').click(function(e) {
        e.preventDefault();
        $(this).addClass('hidden');
        $('.js-categories-group').removeClass('hidden');
        $('.js-categories-next').removeClass('hidden');
        $('.js-additional-categories-group').fadeOut(200,function() {
            const button = $('.js-categories-next:visible')
            if (button.closest('div').hasClass('categories__mobile-button'))
                button.get(0).scrollIntoView({block: "center", behavior: "smooth"});
        });
    })


    // Обработчик отрытия любого попапа
    $('.js-open-popup').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        const popupId = $(this).data('popup-id');
        $(`.js-popup`).fadeOut(300);
        $('.js-popup-overlay').fadeIn(300);
        $(`#${popupId}`).fadeIn(300);
        $('html,body').addClass('pop-up-no-scroll');
        if ($(`#${popupId}`).find('.js-tab-btn')) {
            $(`#${popupId}`).find('.js-tab-btn').eq(0).click().click()
        }
    })
    // Обработчик отрытия заполненого попапа
    $('.js-fill-popup').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        const popupId = $(this).data('popup-id');
        const data = JSON.parse($(this).attr('data-popup-data'));
        const tab = $(this).attr('data-popup-tab')
        $(`.js-popup`).fadeOut(300);
        $('.js-popup-overlay').fadeIn(300);
        $('html,body').addClass('pop-up-no-scroll');
        $(`#${popupId}`).find('input,textarea').each(function(){
            $(this).val(data[$(this).attr('name')])
        })
        $(`#${popupId}`).fadeIn(300);
        if (tab) {
            $(`#${popupId}`).find(`[data-target]`).addClass('disabled')
            $(`#${popupId}`).find(`[data-target="${tab}"]`).removeClass('disabled').click().click()
        }
    })
    // Обработчик закрытия попапа по оверлею и кнопке
    $('.js-popup-overlay,.js-close-popup').click(function(e) {
        e.stopPropagation();
        $('.js-popup-overlay,.js-popup').fadeOut(300);
        $('html,body').removeClass('pop-up-no-scroll');
    })
    // Обработчик открытия каталога
    $('.js-catalog-button').click(function() {
        $('.js-popup-catalog').fadeToggle(300,function() {
            if (!$('.js-popup-catalog').hasClass('active')) {
                $('.js-popup-catalog').removeClass('visible');
            } else {
                $('.js-popup-catalog').addClass('visible');
            }
        });
        $('.js-popup-catalog').toggleClass('active');
        if (!$('.js-sidebar-menu').hasClass('active')) {
            $('.js-header-overlay').fadeToggle(300);
            $('html,body').toggleClass('no-scroll');
        }
    })
    // Обработчик открытия мобильного меню
    $('.js-burger-menu').click(function(){
        $('.js-sidebar-menu').toggleClass('active')
        $('.js-header-overlay').fadeToggle(300);
        $('html,body').toggleClass('no-scroll');
    })
    /*******/
    /******* Сдвигающиеся кнопки [cart] [catalog] ********/
    $('.js-tab-buttons-pull').each(function(){
        const _this = $(this);
        const buttons = _this.find('.js-tab-buttons-pull-btn');
        const preactiveButton = _this.find('.js-tab-buttons-pull-btn.active');
        const marker = _this.find('.js-tab-btn-pull-marker');
        const startButton = preactiveButton.length != 0 ?preactiveButton: buttons.eq(0)
        startButton.addClass('active');
        marker.css({
            transform: `translateX(${startButton.position().left}px)`,
            width:`${startButton.outerWidth()}px`,
        });
        $(window).resize(function() {
            setTimeout(function() {
                marker.css({
                    transform: `translateX(${_this.find('.js-tab-buttons-pull-btn.active').position().left}px)`,
                    width:`${_this.find('.js-tab-buttons-pull-btn.active').outerWidth()}px`,
                });
            },300)

        })
        buttons.click(function(){
            buttons.not($(this)).removeClass('active');
            $(this).addClass('active');
            marker.css({
                transform: `translateX(${$(this).position().left}px)`,
                width:`${$(this).outerWidth()}px`,
            });
        })
    })
    /*******табы [cart] ********/
    $('.js-tab-btn').click(function(){
        if ($(this).closest('div').hasClass('opened')) {
            $(this).closest('div').removeClass('opened');
        } else {
            $(this).closest('div').addClass('opened');
        }
        const view = $(this).parent('div').attr('data-view');
        const id = $(this).attr('data-target');
        const current = id !== 'all'?$(this).closest('.js-tabs').find(`.js-tab[data-id="${id}"]`):$(this).closest('.js-tabs').find(`.js-tab`);
        
        switch (view) {
            case 'type':
              $('[name="type"]').val($(this).attr('data-typeid'));
              break;
            case 'delivery':
              $('[name="delivery"]').val($(this).attr('data-deliveryid'));
              break;
          }
      
          if (view == 'delivery') {
            if ($(this).attr('data-deliveryid') == 3) {
              $('.delivery-info-result').hide();
            } else {
              $('.delivery-info-result').show();
            }
          }
        
        $(this).closest('.js-tabs').find('.js-tab:visible').not(current).hide();
        current.show();
    })

    $('.js-tab-btn').each(function() {
        if ($(this).hasClass('active-open')) {
            $(this).click().click()
        }
    })

    /****** info сообщение [all] ******/
    $('.js-close-info').click(function(){
        $(this).closest('.js-info').remove()
    })
    /*****добавить в корзину страница товара  [product]****/
    $('.js-add-to-cart').click(function(){
        let _data = $(this).data();
        let _url = location.host + location.pathname;
        let _this = $(this);

        $.ajax({
            type: "POST",
            url: _url,
            data: {
                key: 'ajax.component.basket',
                ajax: 'add',
                basket_sessid: BX.message('bitrix_sessid'),
                productId: _data.productid,
            },
            dataType: 'json',
            success: function (data) {
                if (data.status) {
                    let item = data.ITEM;

                    if (item.QUANTITY > 0) {
                        if ($('.cart-link .cart-link__coast').length > 0) {
                            $('.cart-link .cart-link__coast').html(data.PRICE.DISCOUNT.FORMAT_VALUE + ' ₽');
                        }

                        $('.js-quantity-input')
                            .val(item.QUANTITY)
                            .attr('data-id', item.ID)
                            .attr('data-productid', item.PRODUCT_ID);

                        _this.parent('div').find('.js-add-to-cart,.js-in-cart').toggleClass('hide').toggleClass('selected')
                    }
                }
            }
        });
    })
    /*****табы страница товара  [product]****/
    $('.js-tab-btn-product').click(function(){
        $(this).closest('.js-tabs').find('.js-tab-btn-product').not($(this)).parent('div').removeClass('opened')
        $(this).parent('div').addClass('opened')
        const id = $(this).attr('data-target');
        const current = $(this).closest('.js-tabs').find(`.js-tab[data-id="${id}"]`);
        $(this).closest('.js-tabs').find('.js-tab:visible').not(current).hide();
        current.show();
        function check(button,content) {
            if (parseFloat(content.css('maxHeight')) >= content[0].scrollHeight) {
                button.hide()
            } else {
                button.show()
            }
        }
        check(current.find('.js-toggle-button'),current.find('.js-toggle-content'))
    })

    /********детали заказа [lk] *******/
    $('.js-order-detail-button,.js-lk-item-button').click(function() {
        $(this).toggleClass('opened');
        $(this).closest('.js-order,.js-lk-item').find('.js-order-detail,.js-lk-item-body').slideToggle(300)
    })

    /**************переключение разделов кабинета на мобильном [lk] ************************/
    $('.js-chapter-btn').click(function(){
        $(this).closest('div').find('.js-chapter-btn').not($(this)).removeClass('active');
        $(this).addClass('active');
        if ($(this).closest('div').hasClass('opened')) {
            $(this).closest('div').removeClass('opened');
        } else {
            $(this).closest('div').addClass('opened');
        }

    })
    /**************переключение видимости параметров поиска на мобильном [lk]*******************/
    $('.js-toggle-search-params').click(function() {
        $('.js-search-params .js-select').selectmenu( "open" );
    })

    if ($('.js-order').length >0 ) {
        const hash = window.location.hash;
        if (hash) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(`.js-order${hash}`).offset().top
            }, 1000);
            $(`.js-order${hash}`).find('.lk-order__button').click()
        }
    }

    /************************* Каталог: скрипты мобильного поведения ***********************************************/
    $(document).on('click','.js-filtration-overlay',function () {
        $('main').removeClass('bx-active')
        $('.js-filtration-overlay').fadeOut(300);
        $('html,body').removeClass('no-scroll');
    })

    $(document).on('click','.js-mobile-filter-btn',function () {
        $('main').toggleClass('bx-active')
        $('.js-filtration-overlay').fadeToggle(300);
        $('html,body').toggleClass('no-scroll');
    })
    /****************************************************************/


}
/*
 * @title App
 * @description Application entry point
 */

// Polyfills
// import 'Utils/_closest.polyfill.js';
// import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Modules
import carousel from 'Modules/carousel/carousel';
// import navbar from 'Modules/navbar/navbar';
import searchOverlay from 'Modules/search-overlay/search-overlay';
import buttons from 'Modules/buttons/buttons';
import catalog from 'Modules/catalog/catalog';
import forms from 'Modules/forms/forms';
import cookies from "Modules/info/cookies";
import select from 'Modules/select/select';
import filtration from 'Modules/filtration/filtration';
import quantity from 'Modules/quantity/quantity';
import preview from "Modules/previews/previews";

// Components
// import collapse from 'Components/collapse.js';
// import smoothScroll from 'Components/smooth-scroll';
// import toggleElement from 'Components/toggle-element';



document.addEventListener('DOMContentLoaded', function() {
  // Modules
  carousel();
  select();
  searchOverlay();
  buttons();
  catalog();
  forms();
  cookies();
  filtration();
  quantity();
  preview();
  // Components


});


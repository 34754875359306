import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/slider.css';
import SimpleBar from 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

window.jQuery = $;
require('jquery-ui-touch-punch');

export default function filtration() {
    window.filtration = () => {


        /************************* Каталог: фильтрация ползунок ***********************************************/

        $('.js-range-slider-wrap').each(function () {
            const _this = $(this);
            const slider = _this.find('.js-range-slider');
            const startmin = _this.find('.js-range-min').attr('min');
            const startmax = _this.find('.js-range-max').attr('max');
            const currentmax = _this.find('.js-range-max').attr('data-max-value');
            const currentmin = _this.find('.js-range-min').attr('data-min-value');
            const minInput = _this.find('.js-range-min');
            const maxInput = _this.find('.js-range-max');
            const inputs = _this.find('.js-range-min,.js-range-max')
            const form = _this.closest('form');

            inputs.on('input change', function () {
                let min = Number(minInput.val());
                let max = Number(maxInput.val());
                if (min > max) {
                    max = min;
                }
                slider.slider("values", [min, max]);
            })

            form.on('reset', function () {
                let min = Number(minInput.attr('data-min-value'));
                let max = Number(maxInput.attr('data-max-value'));
                slider.slider("values", [min, max]);
            })

            const checkRange = (ui) => {
                const width = $(ui.handle).closest('.ui-slider').find('.ui-slider-range').width()
                if (width < $(window).width() * 0.036) {
                    document.documentElement.style.setProperty(
                        '--price-offset',
                        `100%`
                    );
                } else {
                    document.documentElement.style.setProperty(
                        '--price-offset',
                        `0`
                    );
                }
            }

            slider.slider({
                animate: "slow",
                range: true,
                max: Number(startmax),
                min: Number(startmin),
                values: [currentmin, currentmax],
                slide: function (event, ui) {
                    minInput.val(ui.values[0]);
                    maxInput.val(ui.values[1]);
                    _this.find('.ui-slider-handle').eq(0).attr('data-value', ui.values[0])
                    _this.find('.ui-slider-handle').eq(1).attr('data-value', ui.values[1])
                    checkRange(ui)
                },
                create: function (event, ui) {
                    // minInput.val(currentmin);
                    // maxInput.val(currentmax);
                    _this.find('.ui-slider-handle').eq(0).attr('data-value', currentmin)
                    _this.find('.ui-slider-handle').eq(1).attr('data-value', currentmax)
                },
                change: function (event, ui) {
                    minInput.val(ui.values[0]);
                    maxInput.val(ui.values[1]);
                    _this.find('.ui-slider-handle').eq(0).attr('data-value', ui.values[0])
                    _this.find('.ui-slider-handle').eq(1).attr('data-value', ui.values[1])
                    minInput.keyup()
                    maxInput.keyup()
                },
            })
        })

        /***********************************************/

        /************************* Каталог: разворачивание сворачивание элементов фильтра ***********************************************/

        $('.js-filter-btn').click(function () {
            const _this = $(this)
            _this.closest('.js-filter').find('.js-filter-items').slideToggle(200, function () {
                if ($(this)[0].scrollElement) {
                    $(this)[0].scrollElement.unMount();
                    delete $(this)[0].scrollElement;
                } else {
                    var scroll = new SimpleBar($(this)[0], {autoHide: false})
                    $(this)[0]['scrollElement'] = scroll;
                }
            });
            _this.closest('.js-filter').find('.js-filter-btn').toggleClass('bx-active');

        })
        /*************************************************/

        /************************* Каталог и Корзина: горизотально сдвигающийся пул кнопок ***********************************************/

        $('.js-buttons-pull').each(function () {
            const buttons = $(this).find('.js-buttons-pull-btn');
            const marker = $(this).find('.js-btn-pull-marker');
            const _this = $(this);
            buttons.eq(0).addClass('bx-active');
            marker.css({
                transform: `translateX(${buttons.eq(0).position().left}px)`,
                width: `${buttons.eq(0).outerWidth()}px`,
            });
            buttons.click(function () {
                buttons.removeClass('bx-active');
                $(this).addClass('bx-active');
                marker.css({
                    transform: `translateX(${$(this).position().left}px)`,
                    width: `${$(this).outerWidth()}px`,
                });
            })
            $(window).resize(function () {
                marker.css({
                    transform: `translateX(${_this.find('.bx-active').position().left}px)`,
                    width: `${_this.find('.bx-active').outerWidth()}px`,
                });
            })
            $(this).find('.js-mobile-filter-reset').click(function () {
                    const form = $(this).closest('form');
                    const timeOut = setTimeout(function () {
                        form[0].reset();
                        buttons.removeClass('bx-active');
                        buttons.eq(0).addClass('bx-active');
                        marker.css({
                            transform: `translateX(${buttons.eq(0).position().left}px)`,
                            width: `${buttons.eq(0).outerWidth()}px`,
                        });
                        clearTimeout(timeOut)
                        $('main').removeClass('bx-active')
                        $('.js-filtration-overlay').fadeOut(300);
                        $('html,body').removeClass('no-scroll');
                    }, 200)

                }
            )
        })
        const toggleButtons = $('.js-toggle-button');

        toggleButtons.each( function() {

            const _this = $(this)
            function check(button,content) {
                console.log(parseFloat(content.css('maxHeight')),content[0].scrollHeight)
                if (parseFloat(content.css('maxHeight')) >= content[0].scrollHeight) {
                    button.hide()
                } else {
                    button.show()
                }
            }
            check(_this,_this.closest('.js-toggle-parent').find('.js-toggle-content'))

            $(window).resize(function() {
                check(_this,_this.closest('.js-toggle-parent').find('.js-toggle-content'))
            })

            $(this).click( function(e) {
                e.stopPropagation();
                const parent = $(this).closest('.js-toggle-parent');
                const content = parent.find('.js-toggle-content');
                const hideButton = parent.find('.js-toggle-button.hide');
                if (content.hasClass('hide')) {
                    content[0].style.maxHeight = `${content[0].scrollHeight}px`;
                    content.removeClass('hide');
                } else {
                    content.addClass('hide');
                }
                $(this).addClass('hide');
                hideButton.removeClass('hide');
            });
        })
    }
    /**********************/
    window.filtration();
}
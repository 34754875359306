/********** [contacts] **********/
import $ from 'jquery';

var ya_map = {
    init: function() {
        this.init_cache();

        if (this.$map.length > 0) {
            this.api(this.src, this.create);
        }
    },

    init_cache: function() {
        this.$map = $("#ya-map");

        if (this.$map.length > 0) {
            this.coords = this.$map.data("coords");
            this.marker = this.$map.data("marker");
        }

        this.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
    },

    api: function(src, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;

        document.getElementsByTagName("head")[0].appendChild(script);

        if (callback) script.onload = callback;
    },

    create: function() {
        ymaps.ready(function() {
            var myMap = new ymaps.Map(
                    "ya-map",
                    {
                        center: ya_map.coords,
                        zoom: 16,
                        controls: []
                    },
                    {
                        searchControlProvider: "yandex#search"
                    }
                ),
                myPlacemark = new ymaps.Placemark(
                    myMap.getCenter(),
                    {
                        balloonContent: ya_map.balloon
                    },
                    {
                        iconLayout: "default#imageWithContent",
                        iconImageHref: ya_map.marker,
                        iconImageSize: [80, 100],
                        iconImageOffset: [-40, -100]
                    }
                );

            myMap.geoObjects.add(myPlacemark);
            myMap.behaviors.disable("scrollZoom");
            const isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i)
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i)
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i)
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i)
                },
                any: function () {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
                }
            }
            if (isMobile.any()) {
                myMap.behaviors.disable('drag')
            }
        });
    }
};

$(window).on('load',function() {
    if ($("#ya-map").length>0) {
        ya_map.init();
    }
});

/************/

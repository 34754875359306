
// import $ from 'jquery';
// import Swiper from 'swiper/swiper-bundle.min';
// import Swiper and modules styles
// import 'swiper/swiper-bundle.min.css';

export default function carousel() {

  const heroProductCarousel = $('.js-hero-product-carousel');
  const productSlider = $('.js-product-slider');
  const brandsCarousel = $('.js-brands-slider');
  const newsCarousel = $('.js-news-slider');
  const advantageCarorusel = $('.js-advantages');
  const productCarorusel = $('.js-product-carousel');
  const productPreviewCarorusel = $('.js-product-preview-carousel');

  // When Carousel exists

  /**********/
  if (heroProductCarousel.length>0) {
    new Swiper('.js-hero-product-carousel', {
      loop:true,
      centeredSlides: true,
      slidesPerView: "auto",
    })

  }
  /**********************/
  if (productSlider.length>0) {
      new Swiper('.js-product-slider', {
        slidesPerView: 2,
        slidesPerGroup:2,
        slideVisibleClass:'swiper-slide-visible',
        slideClass:'swiper-slide',
        navigation: {
          nextEl: ".swiper-button--next",
          prevEl: ".swiper-button--prev",
        },
        breakpoints:{
      '1023': {
        slidesPerView: 5,
        slidesPerGroup:5,
        slideClass:'swiper-slide:not(.last)',
      },
    }
      });

  }
  /*************/
  if (brandsCarousel.length>0) {
    new Swiper('.js-brands-slider',{
      slidesPerView:2,
      slidesPerGroup: 2,
      loop:true,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        '1023': {
          slidesPerView:4,
          slidesPerGroup: 4
        }}
    })
  }
  /**************/
  if (newsCarousel.length>0) {
    new Swiper('.js-news-slider',{
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      navigation: {
        nextEl: ".swiper-button--next",
        prevEl: ".swiper-button--prev",
      },
      slideClass:'swiper-slide',
      breakpoints: {
        '1023': {
          slidesPerView:4,
          slidesPerGroup: 4,
          slideClass:'swiper-slide:not(.last)',
        }}
    })

  }
  /**************/
  if (advantageCarorusel.length>0) {
    const init = function () {
      new Swiper('.js-advantages', {
        slidesPerView: 1,
        grid: {
          fill:'row',
          rows: 2,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          '1023': {
            slidesPerView: 4,
            grid: {
              fill:'row',
              rows: 2,
            },
            enable:false
          },
        },
        resize: () => init()
      })
    }
    init()
  }
  if (productCarorusel.length>0 && productPreviewCarorusel.length>0) {
    const productThumbs =  new Swiper('.js-product-preview-carousel', {
      direction: "vertical",
      slidesPerView: 4,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    })
   new Swiper('.js-product-carousel', {
      slidesPerView: 1,
       effect: 'fade',
       fadeEffect: {
         crossFade: true
       },
      thumbs: {
        swiper: productThumbs
      }
    })
  }
}



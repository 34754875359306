
import $ from 'jquery';

export default function preview() {   

    /**
     * For module pumpa.wishlist [all]
     */
    function wishlist(element_id)
    {
        $.ajax({
            type: "POST",
            url: '/ajax/wishlist.php',
            data: {
                ELEMENT_ID: element_id,
            },
            dataType: "json",
            timeout: 5000,
            beforeSend: function (jqXHR, settings) {

            },
            error: function () {
                console.warn('Error ajax sending!');
            },
            success: function (data) {

                console.log(data);

                if (!data) {
                    console.warn('Error! data result is empty!');
                } else {
                    if (data.status == true)
                    {


                    }else{

                    }
                }
            }
        });
    }
    $('.js-product-to-favorite').click(function ()
    {
        var element_id = $(this).data('element_id');

        if (!$(this).hasClass('selected'))
        {
            //add wish
            wishlist(element_id);
            $(this).addClass('selected');
        }else{
            //delete wish
            wishlist(element_id);
            $(this).removeClass('selected');
        }

    });
    

}

import $ from 'jquery'
import 'jquery-ui/ui/widgets/selectmenu';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'selectize/dist/js/selectize.min';
import 'selectize/dist/css/selectize.css';
import SimpleBar from "simplebar";

export default function select() {
    $('.js-select').selectmenu({
        open: function( event, ui ) {
            const selectedText = $(event.target).parent('div').find('.ui-selectmenu-text').text();
            const id = $(event.target).attr('id');
            const list = $(`#${id}-menu`).find('li');
            list.each(function(){
                if ($(this).find('div').text() === selectedText) {
                    $(this).addClass('active')
                } else {
                    $(this).removeClass('active')
                }
            })
        },
        change: function( event, ui ) {
            switch (event.target.dataset.mode) {
                case 'searchtype':
                    $(event.target).closest('.select').find('.search-for-params__input').attr('placeholder',`Найти заказ по ${ui.item.label}`)
                    // $(event.target).closest('.select').find('.js-toggle-search-params:visible').click()
                    break;
                default:
                    var value = $(event.target).val();
                    var regex = /SORT=[a-z\_]{1,}/g;
                    var url = document.location.href;
                    if(url.indexOf('SORT=')!= '-1')
                    {
                        var URL = url.replace(regex, 'SORT=' + value);
                    }else{
                        if(url.indexOf('?')!= '-1')
                        {
                            var URL = url+'&SORT=' + value;
                        }else{
                            var URL = url+'?SORT=' + value;
                        }
                    }
                window.location = URL;
            }
        }

    })
    $('.js-extended-select').each(function() {
        const _this = $(this)
        let scrollElement = {}
        const selectize = $(this).selectize({
            create: false,
            sortField: "text",
            placeholder:$(this).attr('placeholder'),
            onDropdownOpen: function() {
                setTimeout(function() {
                    scrollElement =  new SimpleBar(_this.closest('div').find('.selectize-dropdown-content')[0],{ autoHide: false })
                },0)
            },
            onDropdownClose: function() {
                scrollElement.unMount();
            }
        });

    })

    /******** Общий tooltip ********/
    $('.js-lk-item,.js-profile').tooltip();
    // $(".lk-item__comment").tooltip().tooltip("open");

}
import $ from 'jquery'

/********* Корзина: Счетчик количества товара ***************/

export default function quantity() {
    var t;

    $('.js-quantity-dec').click(function () {
        $(this).closest('.js-quantity').find('.js-quantity-input')[0].stepDown();
        recalcCoast($(this), $(this).closest('.js-quantity').find('.js-quantity-input').val());
    })
    $('.js-quantity-inc').click(function () {
        $(this).closest('.js-quantity').find('.js-quantity-input')[0].stepUp();
        recalcCoast($(this), $(this).closest('.js-quantity').find('.js-quantity-input').val());
    })

    $('.js-quantity-input').on('input', function () {
        recalcCoast($(this), $(this).closest('.js-quantity').find('.js-quantity-input').val());
    })

    function recalcCoast(elm, quantity) {
        let form = $('#cart');
        let _data = elm.parent('.quantity').find('input').data();
        let total = 0;
        var totalDiscount = 0;
        const pricePerOne = Number(elm.closest('.js-product').find('.js-price-per-one').data("price") ?
            elm.closest('.js-product').find('.js-price-per-one').data("price") :
            elm.closest('.js-product').find('.js-price-per-one').text().replace(' ', ''));
        const coast = elm.closest('.js-product').find('.js-coast');

        if (quantity < 1) quantity = 1;

        clearTimeout(t);
        t = setTimeout(function (input) {
            elm.closest('.js-product').val(quantity);

            $.ajax({
                type: "POST",
                url: form.attr('action'),
                data: {
                    key: 'ajax.component.basket',
                    ajax: 'quantity',
                    basket_sessid: BX.message('bitrix_sessid'),
                    id: _data.id,
                    productId: _data.productid,
                    quantity: quantity
                },
                dataType: 'json',
                success: function (data) {
                    if (data.status) {
                        if (data.PRICE.DISCOUNT.VALUE <= 4000) {
                            $('.delivery-info-text-1').show();
                            $('.delivery-info-text-2').hide();
                        } else {
                            $('.delivery-info-text-1').hide();
                            $('.delivery-info-text-2').show();
                        }

                        if ($('.cart-link .cart-link__coast').length > 0) {
                            $('.cart-link .cart-link__coast').html(data.PRICE.DISCOUNT.FORMAT_VALUE + ' ₽');
                        }

                        coast.text(new Intl.NumberFormat().format(pricePerOne * quantity).replace(',', '.'));
                        $('.js-coast').each(function () {
                            total += Number($(this).text().replace(/[\s\u00A0]/g, ''));
                            totalDiscount += $(this).data('totaldiscount') * ($(this).parents('.js-product').find('.quantity__input').val() * 1);
                        });
                        $('.js-total-coast').text(new Intl.NumberFormat().format(total));
                        $('.js-total-discount').text(new Intl.NumberFormat().format(totalDiscount));
                    }
                }
            });
        }, 500, this);
    }
}

/**************************/
/**
 * Search
 */

import $ from 'jquery';

var testData = [];
function num_word(value, words){
    value = Math.abs(value) % 100;
    var num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0];
    return words[2];
}

function searchTitle(_this) {
    let _v = _this.val();
    let _c = 3;
    const search = $('.js-search');
    const header = $('header');

    if (_v.length >= _c) {
        let form = $('.js-search-form');
        $.ajax({
            type: "POST",
            url: form.attr('action'),
            data: {
                key: 'ajax.component.search',
                basket_sessid: BX.message('bitrix_sessid'),
                p: _v
            },
            dataType: 'json',
            success: function (data) {
                var results = [];
                var items = data.ITEMS;
                if (items !== null) {
                    if (items.length > 0) {
                        results = items;
                        $('.js-search-result-list').html('');
                        $('.search__more').show();
                        $('.js-search-results-length').text(data.COUNT + ' ' + num_word(data.COUNT, ['результат', 'результа', 'результов']));

                        results.forEach((result) => {
                            search.each(function() {
                                let resultItem = $(this)[0].querySelector('.js-search-result-template').cloneNode(true);
                                const list = $(this).find('.js-search-result-list');

                                resultItem.classList.remove('hidden');
                                resultItem.querySelector('.js-search-brand').innerHTML = result.brand
                                resultItem.querySelector('.js-search-name').innerHTML = result.name
                                resultItem.querySelector('.js-search-sku').innerHTML = result.sku
                                resultItem.querySelector('.js-search-result-image').src = result.image
                                resultItem.querySelector('.js-search-price').innerHTML = result.price
                                resultItem.querySelector('a').href = result.url

                                list[0].appendChild(resultItem);
                            })
                        });
                    } else {
                        $('.js-search-result-list').html('<div class="no_product">К сожалению мы не нашли ответ на Ваш запрос.</div>');
                        $('.search__more').hide();
                    }
                } else {
                    $('.js-search-result-list').html('<div class="no_product">К сожалению мы не нашли ответ на Ваш запрос.</div>');
                    $('.search__more').hide();
                }

                search.addClass('active');
                search.addClass('focused');
            }
        });
    } else {
        search.removeClass('active');
        header.removeClass('focused');
    }
}

export default function searchOverlay() {
    var _t;
    const search = $('.js-search');
    const header = $('header');
    const searchForm = search.find('.js-search-form');
    const input = searchForm.find('input');
    const results = testData
    $('[name="p"]').on('input', function (e) {
        clearTimeout(_t);
        let _this = $(this);

        _t = setTimeout(function (input) {
            searchTitle(_this);
        }, 700, this);
    });

    input.focus(function() {
        if ($('.header--mobile .js-search-result-list > .js-search-result-template').length > 0) {
            search.addClass('active');
            header.addClass('focused');
        }
    });
    // input.focusout( function(e) {
    //    setTimeout(()=>{
    //        search.removeClass('active');
    //        header.removeClass('focused');
    //    },100)
    // });
    $(document).click(function(e) {
        if (!$(e.target).closest('.js-search').length && search.hasClass('active')) {
            setTimeout(()=>{
                search.removeClass('active');
                header.removeClass('focused');
            },100)
        }
    })


    // TODO: Добавить асинхронный  запрос на бэк

    results.forEach((result) => {
        search.each(function() {
            let resultItem = $(this)[0].querySelector('.js-search-result-template').cloneNode(true);
            const list = $(this).find('.js-search-result-list');

            resultItem.classList.remove('hidden');
            resultItem.querySelector('.js-search-brand').innerHTML = result.brand
            resultItem.querySelector('.js-search-name').innerHTML = result.name
            resultItem.querySelector('.js-search-sku').innerHTML = result.sku
            resultItem.querySelector('.js-search-result-image').src = result.image
            resultItem.querySelector('.js-search-price').innerHTML = result.price
            resultItem.querySelector('a').href = result.url

            list[0].appendChild(resultItem);
        })
    })



}


import $ from 'jquery';
import 'jquery-validation';
import Inputmask from "inputmask/lib/inputmask";
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'


export default function forms() {
    // dadata
    $(".dadata").each(function () {
        const _this = $(this);
        _this.suggestions({
            token: "4e22fdfc108033cb5b07187eb261a8fe8fddff4f",
            type: _this.attr('data-dadata-type'),
            /* Вызывается, когда пользователь выбирает одну из подсказок */
            onSelect: function (suggestion) {
                console.log(suggestion)
                if (_this.attr('data-dadata-props')) {
                    const props = _this.attr('data-dadata-props').split('.')
                    let value = suggestion.data
                    props.forEach((prop) => {
                        value = value[prop]
                    })
                    _this.val(value)
                }
            }
        });
    })

    // Form validation
    const formValidate = function () {
        $.validator.addMethod(
            "regexp",
            function (value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            },
            "Please check your input."
        );

        $.validator.addClassRules({
            phone: {
                minlength: 16,
                regexp: "[^_]+$",
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            required: {
                required: true
            },
            onlytext: {
                regexp: "^[а-яеА-ЯЁ'.\\s]{1,999}$",
            },
            number: {
                number: true,
            },
            birthday: {
                regexp: "[^_]+$"
            },
        });
        $.validator.setDefaults({ ignore: ":hidden:not(select)" })
        $('form').each(function () {
            $(this).validate({
                errorPlacement: function (error, element) {
                    error.remove();
                },
                // submitHandler: function(form) {
                //     // console.log('submithandler');
                //     // console.log($(form).validator())
                //     // if (!$(form).hasClass('valid')) {
                //     //     $(form).addClass('valid');
                //     //     $(form).submit();
                //     // }
                // },
                highlight: function (element) {
                    $(element).parent('div').addClass('error');
                    $(element).addClass('error');
                },
                unhighlight: function (element) {
                    $(element).parent('div').removeClass('error');
                    $(element).removeClass('error');
                }
            });
        });
    };

    formValidate();


    //Mask

    const maskInit = function () {
        Inputmask({
            mask: "+7(999)999-99-99",
            showMaskOnHover: false
        }).mask($('input.phone'))
        Inputmask({
            mask: "99.99.9999",
            showMaskOnHover: false
        }).mask($('input.birthday'))


    }
    maskInit()


    // Get code form
    $('.js-login-form').submit(function (e) {
        e.preventDefault();
        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }
        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            data[this.name] = this.value
        })

        const successHandler = function () {
            $('.js-resend-code').prop('disabled', true);
            let counter = 60;
            form.closest('.js-popup').addClass('code');
            const interval = setInterval(() => {
                counter--;
                $('.js-counter').text(counter);
                if (counter === 0) {
                    clearInterval(interval);
                    $('.js-resend-code').prop('disabled', false);
                }
            }, 1000)
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.user.authorization',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(resData) {
                if (resData.status) {
                    $('.js-post-code-form').find('[name="phone"]').val(data.phone);
                    form.find('[name="phone"]').attr('disabled', true);
                    successHandler(data);
                } else {
                    var error = resData.error;

                    if (error.length > 0) {
                        form.find('[name="phone"]').addClass('error').parent('.form-field').addClass('error');
                    }

                    console.log(error);
                }
            }
        })
    })

    //Send code form

    $('.js-post-code-form').submit(function (e) {
        e.preventDefault();
        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }
        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            data[this.name] = this.value
        })

        const successHandler = function () {
            document.location.reload();
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.user.authorization',
                ajax: 'smsCode',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(resData) {
                if (resData.status) {
                    successHandler(resData);
                } else {
                    var error = resData.error;

                    if (error.length > 0) {
                        console.log(error);
                    }
                }
            }
        })
    })

    $('.js-signup-form').submit(function (e) {
        e.preventDefault();
        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }
        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            data[this.name] = this.value
        })

        const successHandler = function () {
            $('.js-resend-code').prop('disabled', true);
            let counter = 60;
            form.closest('.js-popup').addClass('code');
            const interval = setInterval(() => {
                counter--;
                $('.js-counter').text(counter);
                if (counter === 0) {
                    clearInterval(interval);
                    $('.js-resend-code').prop('disabled', false);
                }
            }, 1000)
        }

        const errorHandler = function () {
            form.closest('.js-popup').addClass('auth');
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.user.registration',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(resData) {
                if (resData.status) {
                    $('.js-post-signup-code-form').find('[name="phone"]').val(data.phone);
                    form.find('[name="phone"]').attr('disabled', true);
                    successHandler(data);
                } else {
                    var error = resData.error;

                    if (error.length > 0) {
                        form.find('[name="phone"]').addClass('error').parent('.form-field').addClass('error');
                    }

                    console.log(error);
                }
            }
            //error: function (data) { errorHandler(data) },
        })
    })

    $('.js-post-signup-code-form').submit(function (e) {
        e.preventDefault();
        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }
        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}
        fields.each(function () {
            data[this.name] = this.value;
        });

        const successHandler = function () {
            document.location.reload();
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.user.registration',
                ajax: 'smsCode',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(resData) {
                if (resData.status) {
                    successHandler(resData);
                } else {
                    var error = resData.error;

                    if (error.length > 0) {
                        console.log(error);
                    }
                }
            }
        });
    });

    /**
     * Recall [all]
     */
    $('form[name="SIMPLE_FORM_1"]').submit(function (e) {
        e.preventDefault();

        if (Object.keys($(this).data("validator").invalid).length) {
            return false;
        }

        var form = $(this);
        var fields = form.find('input,textarea');
        var data = {};
        fields.each(function () {
            data[this.name] = this.value;
        });

        var successHandler = function successHandler(data) {
            console.log(data);
            $('.js-popup').not('.js-popup#popup-confirm-send-message').fadeOut(300);
            $('.js-popup#popup-confirm-send-message').fadeIn(300);
            var timeout = setTimeout(function () {
                $('.js-popup,.js-popup-overlay').fadeOut(300);
                clearTimeout(timeout);
            }, 3000);
        };

        var errorHandler = function errorHandler() { };

        $.ajax({
            method: 'GET',
            url: '/ajax/web_form_ordercall.php',
            data: data,
            success: function (data) { successHandler(data) },
            error: function (data) { errorHandler(data) }
        });
    });

    /**
     * Subscribe [all]
     */


    $('.js-subscribe-from').submit(function (e) {
        e.preventDefault();

        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }

        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            data[this.name] = this.value
        })

        const successHandler = function (data) {
            form.find('button[type="submit"]').attr('disabled', 'disabled')
            $('.subscribe__thanks-inner').html(data);
            $('.js-subscribe-thanks').fadeIn(300);
            const timeout = setTimeout(() => {
                $('.js-subscribe-thanks').fadeOut(300);
                form.find('button[type="submit"]').attr('disabled', false)
                clearTimeout(timeout);
            }, 3000)
        }

        const errorHandler = function (data) {
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: data,
            success: function (data) { successHandler(data) },
            error: function (data) { errorHandler(data) },
        })
    })
    /**********Промокод [cart] **************/
    $('.js-promocode').submit(function (e) {
        e.preventDefault();

        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }

        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            data[this.name] = this.value
        })

        const successHandler = function () {
            form.find('.js-promocode-inner').removeClass('error');
            form.find('.js-promocode-error').fadeOut(300);
            form.find('.js-promocode-btn').addClass('hidden');
            form.find('.js-promocode-success,.js-promocode-remove').addClass('visible');
            form.closest('.js-parent-wrap').find('.js-discount-info').css({ "display": "flex" }).hide().fadeIn(300);
            form.find('input').attr('disabled', 'disabled');
        }

        const errorHandler = function () {
            /******** Только для демонстрации, убрать при интеграции **********/
            successHandler();
            /********************/
            form.find('.js-promocode-error').fadeIn(300);
            form.find('.js-promocode-inner').addClass('error');
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.component.basket',
                ajax: 'promocode',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(data) {
                if (data.status) {
                    //очищаем ошибкт промокода
                    $('.js-promocode-error').html('');
                    $('.js-promocode-error').hide();

                    if (data.PRICE.DISCOUNT.VALUE <= 4000) {
                        $('.delivery-info-text-1').show();
                        $('.delivery-info-text-2').hide();
                    } else {
                        $('.delivery-info-text-1').hide();
                        $('.delivery-info-text-2').show();
                    }

                    //переопределяем цены товаров
                    let items = data.ITEMS;
                    for (e in items) {
                        $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').removeClass('cart-propducts__product-price-discount cart-propducts__product-price-per-one');
                        if (items[e].PRICE.PRICE.VALUE != items[e].PRICE.DISCOUNT.VALUE) {
                            $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').addClass('cart-propducts__product-price-discount');
                        } else {
                            $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').addClass('cart-propducts__product-price-per-one');
                        }
                        $('#cart-propduct-' + items[e].ID).find('.js-price-per-one').html(items[e].PRICE.DISCOUNT.FORMAT_VALUE).attr('data-price', items[e].PRICE.DISCOUNT.VALUE);
                        $('#cart-propduct-' + items[e].ID).find('.js-coast').html(items[e].PRICE.DISCOUNT.FORMAT_FINAL).attr('data-totaldiscount', items[e].PRICE.TOTAL_DISCOUNT.VALUE);
                    }

                    //итоговая стоимость
                    if (data.PRICE.TOTAL_DISCOUNT.VALUE > 0) { $('.cart-general_discount').show(); }
                    $('.js-total-discount').html(data.PRICE.TOTAL_DISCOUNT.FORMAT_VALUE);
                    $('.js-total-coast').html(data.PRICE.DISCOUNT.FORMAT_VALUE);

                    successHandler(data);
                } else {
                    let error = data.error;
                    if (error.length > 0) {
                        $('.js-promocode-error').html('');
                        for (e in error) {
                            $('.js-promocode-error').append((e == 0 ? '' : '<br />') + error[e].TITLE)
                        }
                        $('.js-promocode-error').show();
                    }
                }
            },
            error: function (data) { errorHandler(data) },
        })
    })

    /************* Корзина: Удаление промокода *************/

    $('body').on('click', '.js-promocode-remove', function (e) {
        let form = $('.promocode.js-promocode');

        $.ajax({
            type: "POST",
            url: form.attr('action'),
            data: {
                key: 'ajax.component.basket',
                ajax: 'promocode-remove',
                basket_sessid: BX.message('bitrix_sessid')
            },
            dataType: 'json',
            success: function (data) {
                if (data.status) {
                    //очищаем ошибкт промокода
                    $('.js-promocode-error').html('');
                    $('.js-promocode-error').hide();

                    if (data.PRICE.DISCOUNT.VALUE <= 4000) {
                        $('.delivery-info-text-1').show();
                        $('.delivery-info-text-2').hide();
                    } else {
                        $('.delivery-info-text-1').hide();
                        $('.delivery-info-text-2').show();
                    }

                    console.log(data.ITEMS)
                    //переопределяем цены товаров
                    let items = data.ITEMS;
                    for (e in items) {
                        $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').removeClass('cart-propducts__product-price-discount cart-propducts__product-price-per-one');
                        if (items[e].PRICE.PRICE.VALUE != items[e].PRICE.DISCOUNT.VALUE) {
                            $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').addClass('cart-propducts__product-price-discount');
                        } else {
                            $('#cart-propduct-' + items[e].ID).find('.cart-propducts__product-price').addClass('cart-propducts__product-price-per-one');
                        }
                        $('#cart-propduct-' + items[e].ID).find('.js-price-per-one').html(items[e].PRICE.DISCOUNT.FORMAT_VALUE).attr('data-price', items[e].PRICE.DISCOUNT.VALUE);
                        $('#cart-propduct-' + items[e].ID).find('.js-coast').html(items[e].PRICE.DISCOUNT.FORMAT_FINAL).attr('data-totaldiscount', items[e].PRICE.TOTAL_DISCOUNT.VALUE);
                    }

                    //итоговая стоимость
                    if (data.PRICE.TOTAL_DISCOUNT.VALUE <= 0) {
                        $('.cart-general_discount').hide();
                    }
                    $('.js-total-discount').html(data.PRICE.TOTAL_DISCOUNT.FORMAT_VALUE);
                    $('.js-total-coast').html(data.PRICE.DISCOUNT.FORMAT_VALUE);

                    form[0].reset();
                    form.find('input').attr('disabled', false);
                    form.find('.js-promocode-btn').removeClass('hidden');
                    form.find('.js-promocode-success,.js-promocode-remove').removeClass('visible');
                    form.closest('.js-parent-wrap').find('.js-discount-info').fadeOut(300);
                } else {
                    let error = data.error;
                    if (error.length > 0) {
                        $('.js-promocode-error').html('');
                        for (e in error) {
                            $('.js-promocode-error').append((e == 0 ? '' : '<br />') + error[e].TITLE)
                        }
                        $('.js-promocode-error').show();
                    }
                }
            }
        });
    });

    /************* Корзина: Удаление товара из корзны  [cart] *************/

    $('body').on('click', '.js-remove-product', function (e) {
        let _data = $(this).data();

        $.ajax({
            url: BX.message('PB__URL'),
            data: {
                key: 'ajax.component.basket',
                ajax: 'remove',
                basket_sessid: BX.message('bitrix_sessid'),
                id: _data.id
            },
            dataType: 'json',
            type: "POST",
            success: function (data) {
                if (data.status) {
                    let product = $('#cart-propduct-' + _data.id);
                    product.remove();

                    $('.cart-propducts__products').each(function (e) {
                        if ($(this).find('> div').length < 1) {
                            $(this).prev().remove();
                            $(this).remove();
                        }
                    });

                    if ($('.cart-propducts__products').length < 1) {
                        $('.cart-main').remove();
                        $('.catalog-main').show();
                    }

                    if (data.PRICE.DISCOUNT.VALUE <= 4000) {
                        $('.delivery-info-text-1').show();
                        $('.delivery-info-text-2').hide();
                    } else {
                        $('.delivery-info-text-1').hide();
                        $('.delivery-info-text-2').show();
                    }

                    //итоговая стоимость
                    if (data.PRICE.TOTAL_DISCOUNT.VALUE <= 0) {
                        $('.cart-general_discount').hide();
                    } else {
                        $('.cart-general_discount').show();
                    }
                    $('.js-total-discount').html(data.PRICE.TOTAL_DISCOUNT.FORMAT_VALUE);
                    $('.js-total-coast').html(data.PRICE.DISCOUNT.FORMAT_VALUE);
                }
            }
        });

        return false;
    });

    /************* Корзина: Отправка корзины  [cart] *************/

    $('#cart').submit(function (e) {
        e.preventDefault();

        if (Object.keys($(this).data("validator").invalid).length) {
            return false
        }

        const form = $(this);
        const fields = form.find('input,textarea');
        const data = {}

        fields.each(function () {
            if (this.type == 'radio') {
                if (this.checked) {
                    data[this.name] = this.value;
                }
            } else {
                data[this.name] = this.value;
            }
        })

        const successHandler = function () {
            $('.js-popup#popup-confirm-order').fadeIn(300);
            $('.js-popup-overlay').fadeIn(300);
        }

        const errorHandler = function () {
            /**** Только для демонстрации всплывашки убрать при интеграции ****/
            $('.js-popup#popup-confirm-order').fadeIn(300);
            $('.js-popup-overlay').fadeIn(300);
            /***************/
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                key: 'ajax.component.basket',
                basket_sessid: BX.message('bitrix_sessid'),
                data: data
            },
            dataType: 'json',
            success: function success(data) {
                if (data.authorization == true) {
                    $(".js-popup").fadeOut(300);
                    $('.js-popup-overlay').fadeIn(300);
                    $("#".concat('popup-login')).fadeIn(300);
                } else if (data.status == true) {
                    $('.cart-main').html('');
                    $('.catalog-main--no-sidebar').show();

                    $('#popup-confirm-order').find('.popup__commontext a').text(data.ORDER_ID).attr('href', '/personal/orders/');
                    successHandler(data);
                } else {
                    //console.log(data);
                }
            },
            error: function (data) { errorHandler(data) },
        })
    })
    /**************************************/

    /********** Корзина: Сброс группы полей [cart] ***********/
    $('.js-reset-fieldset').click(function () {
        $(this).closest('fieldset').find('input,select').val('');
        $(this).closest('fieldset').find('.js-extended-select')[0].selectize.clear();
    })
    /**************************************/

    /********** Корзина: Заполнение поля при выборе селекта [cart] ***********/
    $('select.js-extended-select').each(function () {
        const _this = $(this)
        $(this)[0].selectize.on('change', function (value) {
            if (_this.attr('data-for-field')) {
                $(_this.attr('data-for-field')).val(value)
            }
        })
    })

    /**************************************/

    /************Личный кабинет список заказов [lk orders]**************/
    if ($('.js-datepicker').length > 0) {
        new AirDatepicker('.js-datepicker', {
            // inline:true,
            autoClose: true,
            multipleDatesSeparator: '-',
            dateFormat:'dd.MM.yy',
            buttons: [
                {
                    content(dp) {
                        return dp.opts.range
                            ? 'Выключить выбор диапазона дат'
                            : 'Включить выбор диапазона дат'
                    },
                    onClick(dp) {
                        dp.update({
                            range: !dp.opts.range
                        })
                    }
                }
            ]
        })
    }

    /*************************************/
    /****************Личный кабинет профиль [lk profile] ************************/
    $('.js-profile form').find('input').on('input', function () {
        let nochanged = true
        $('.js-profile form').find('input').each(function () {
            if (($(this).attr('data-value') && $(this).val() !== $(this).attr('data-value')) ||
                ($(this).attr('data-confirmed') && $(this).val() !== $(this).attr('data-confirmed'))) {
                nochanged = false
            }
        })
        if (!nochanged) {
            $('.js-form-changed').removeClass('hide')
        } else {
            $('.js-form-changed').addClass('hide')
        }
    })

    $('.js-email').on('input', function () {
        console.log($(this).val(), $(this).attr('data-confirmed'))
        if ($(this).val() !== $(this).attr('data-confirmed')) {
            $('.js-email-not-confirmed').removeClass('hide')
            $('.js-email-confirmed').addClass('hide')
        } else {
            $('.js-email-not-confirmed').addClass('hide')
            $('.js-email-confirmed').removeClass('hide')
        }
    })
    /*********************************************************************/
    $('.lk-item-wrap').each(function () {
        $(this).find('.lk-item__mobile-controls').height($(this).find('.lk-item').height())
    })
}

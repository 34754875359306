import $ from 'jquery';

export default function catalog() {
    function syncHeight() {
        document.documentElement.style.setProperty(
            '--window-inner-height',
            `${window.innerHeight}px`
        );
    }

    window.addEventListener('resize', syncHeight);
    window.addEventListener('load', syncHeight);



    $('.js-catalog-btn').click(function() {
        $(this).toggleClass('active');
        $(this).closest('.js-catalog-category').find('.js-catalog-subcategories').slideToggle(200);
    })

    $('.js-header-overlay').click(function() {
        $('.js-popup-catalog').fadeOut(300,function() {
            $('.js-popup-catalog').removeClass('visible');
        });
        $('.js-sidebar-menu').removeClass('active')
        $('.js-header-overlay').fadeOut(300);
        $('.js-popup-catalog').removeClass('active');
        $('html,body').removeClass('no-scroll');
    })

    $(document).click(function(e) {
            if (!$(e.target).closest('.js-popup-catalog').length && $('.js-popup-catalog').hasClass('visible')) {
                $('.js-popup-catalog').fadeOut(300,function() {
                    $('.js-popup-catalog').removeClass('visible');
                });
                $('.js-header-overlay').fadeOut(300);
                $('.js-popup-catalog').removeClass('active');
                $('html,body').removeClass('no-scroll');
            }
    })

}